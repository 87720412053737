import React from 'react';

// Local
import { CSVUploaderPage } from './sections/csvUploader/csvUploader';
import { ETLTriggerPage } from './sections/EtlTrigger/EtlTriggerPage';

// MUI
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Drawer from '@mui/material/Drawer';
import { IconButton, ListItemIcon } from '@mui/material';
import AppsIcon from '@mui/icons-material/Apps';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export const apps = [
    { name: 'CSV Uploader', icon: <></>, app: <CSVUploaderPage /> }
    , { name: 'MySQL to BigQuery ETL', icon: <></>, app: <ETLTriggerPage /> }
]

export const AppsDrawer: React.FC<{ setSelectedApp: React.Dispatch<React.SetStateAction<any>> }> = ({ setSelectedApp }) => {
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen: boolean) => () => { setOpen(newOpen); };

    const DrawerList = <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
        <List>
            <ListItem key={'return'} disablePadding>
                <ListItemButton onClick={() => toggleDrawer(false)}>
                    <ListItemIcon>
                        <ArrowBackIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Close'} />
                </ListItemButton>
            </ListItem>
            {apps.map((app) => (
                <ListItem key={app.name} disablePadding>
                    <ListItemButton onClick={() => setSelectedApp(app)}>
                        <ListItemText primary={app.name} />
                    </ListItemButton>
                </ListItem>
            ))}
        </List>
    </Box>

    return <div style={{ position: 'fixed', top: '0', left: '0' }}>
        <IconButton sx={{ padding: '12px', margin: '12px 0 0 12px' }} onClick={toggleDrawer(true)}>
            <AppsIcon sx={{ fontSize: '42px' }} />
        </IconButton>
        <Drawer open={open} onClose={toggleDrawer(false)}>
            {DrawerList}
        </Drawer>
    </div>
}