import * as React from 'react';
import { EtlContext, EtlHistoryDoc } from './EtlContext';
import Typography from '@mui/material/Typography';
import { Accordion, AccordionActions, AccordionSummary, Button, Snackbar, Tooltip } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';

export const ETLHistory = () => {
    const context = React.useContext(EtlContext)

    return <div style={{ maxHeight: '90%', overflowY: 'scroll', padding: '10px' }}>
        {React.Children.toArray(context.history.map(h => {
            return <ETLHistoryCard data={h} />
        }))}
    </div>
};

export const ETLHistoryCard: React.FC<{ data: EtlHistoryDoc }> = ({ data }) => {
    return <><Accordion sx={{ width: '25vw' }}>
        <AccordionSummary>
            <div>
                <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                    <strong>{data.mode == 'etl' ? "📤 ETL" : '🗑️ Truncate'} on {data.version}</strong> - {new Date(data.unix_secs * 1000).toLocaleString()}
                </Typography>
                <Typography variant="h6" component="div">
                    {data.running ? "Running" : (data.status === 'Success' && data.origin_rows === 0) ? 'No data' : data.status}
                </Typography>
            </div>
        </AccordionSummary>
        <AccordionDetails>
            {data.status === 'Error'
                ? <>
                    <Typography variant="body2">
                        Error message: {data.error_message}
                    </Typography>
                </>
                : data.mode === 'etl'
                    ? <>
                        <Typography variant="body2">
                            MySQL source: <strong>{data?.origin_rows?.toLocaleString() || 0} rows from {data?.origin_urls?.toLocaleString() || 0} URL scrapes</strong>
                            <br /> Logs appended: <strong>{data?.logs_success?.toLocaleString() || 0} OK and {data?.logs_error?.toLocaleString() || 0} blocked URL scrapes</strong>
                            <br /> Reviews appended: <strong>{data?.reviews_rows?.toLocaleString() || 0} reviews from {data?.reviews_urls?.toLocaleString() || 0} distinct URLs</strong>
                        </Typography>
                    </>
                    : <>
                        <Typography variant="body2">
                            Deleted on MySQL: <strong>{data?.origin_rows?.toLocaleString() || 0} rows</strong>
                        </Typography>
                    </>
            }
        </AccordionDetails>
        <AccordionActions>
            {(data.status === 'Success') && (data.origin_rows || 0) > 0 && data.mode == 'etl'
                && <>
                    <SQLQueryButton sqlQuery={getSqlQuery('revert', data.etl_instance_millis)} label="Delete data" />
                    <SQLQueryButton sqlQuery={getSqlQuery('logs', data.etl_instance_millis)} label="Query Logs" />
                    <SQLQueryButton sqlQuery={getSqlQuery('reviews', data.etl_instance_millis)} label="Query Reviews" />
                </>
            }
        </AccordionActions>
    </Accordion >
        <br />
    </>
};

const getSqlQuery = (type: 'logs' | 'reviews' | 'revert', identifier: number | undefined): string => {
    if (identifier) {
        return type === 'logs'
            ? "SELECT * FROM `etl-directories1.octoparse.octoparse_logs` WHERE etl_instance_millis = {id} ".replace('{id}', String(identifier))
            : type === 'reviews'
                ? "SELECT * FROM `etl-directories1.reviews.vw_reviews` WHERE etlInstanceMillis = {id} ".replace('{id}', String(identifier))
                : " DELETE FROM `etl-directories1.reviews.reviews` WHERE etlInstanceMillis = {id}; \n".replace('{id}', String(identifier)) +
                " DELETE FROM `etl-directories1.octoparse.octoparse_logs` WHERE etl_instance_millis = {id}; ".replace('{id}', String(identifier))
    } else {
        return 'An unexpected error was found extracting the SQL query. Please contact your system administrator for troubleshooting.';
    };
};

const SQLQueryButton: React.FC<{ sqlQuery: string, label: string }> = ({ sqlQuery, label }) => {
    const [copied, setCopied] = React.useState(false);

    const handleClick = async () => {
        try {
            await navigator.clipboard.writeText(sqlQuery); // Copy query to clipboard
            setCopied(true); // Update status to indicate copied
        } catch (error) {
            console.error("Failed to copy: ", error);
        }
    };

    const handleCloseSnackbar = () => { setCopied(false); };

    return <div>
        <Tooltip title={sqlQuery} arrow>
            <Button
                variant="contained"
                onClick={handleClick}
            >
                {label}
            </Button>
        </Tooltip>

        <Snackbar
            open={copied}
            autoHideDuration={2000} // Snackbar will hide after 2 seconds
            onClose={handleCloseSnackbar}
            message="SQL query copied to clipboard!"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        />
    </div>
};
