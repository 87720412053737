import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import PublishIcon from '@mui/icons-material/Publish';
import { useContext, useState } from "react";
import { EtlContext } from "./EtlContext";

export const ETLTriggerButton = () => {
    const etlContext = useContext(EtlContext);
    const [modalOpen, setModalOpen] = useState(false);

    const runEtl: () => Promise<void> = async () => {
        setModalOpen(false);
        
        try {
            etlContext.runEtl();
            await new Promise(resolve => setTimeout(resolve, 2000));
            await etlContext.updateHistory();
        } catch (e) {
            console.log(e);
        };
    };

    return <>
        <AlertDialog open={modalOpen} setOpen={setModalOpen} onProceed={runEtl} />
        <Button
            component="label"
            variant="contained"
            disabled={etlContext.status !== 'Rest'}
            tabIndex={-1}
            startIcon={<PublishIcon />}
            onClick={() => setModalOpen(true)}
        >
            Run ETL
        </Button>
    </>
}

const AlertDialog: React.FC<{ open: boolean, setOpen: React.Dispatch<React.SetStateAction<boolean>>, onProceed: () => Promise<void> }> = ({ open, setOpen, onProceed }) => {
    return <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {"Are you sure?"}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                This will transport and transform data from MySQL to BigQuery, appending rows on multiple tables.
                <br />
                These actions are reversible, but might generate cost on BigQuery.
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button onClick={() => onProceed()} autoFocus>
                Run ETL
            </Button>
        </DialogActions>
    </Dialog>


}