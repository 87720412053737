import { useContext } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { FilesContext, TableType } from './fileContext';

const tables = [{ value: 'outscraper', label: 'Outscraper' }, { value: 'texau', label: 'Texau' }];

export const SelectTable = () => {
    const context = useContext(FilesContext)

    return <Box sx={{ minWidth: 240 }}>
        <FormControl fullWidth size='small'>
            <InputLabel id="demo-simple-select-label">Table</InputLabel>
            <Select
                disabled={context.uploading}
                id="demo-simple-select"
                value={context.tableType}
                sx={{backgroundColor: 'white'}}
                label="Table"
                onChange={(event) => context.setTableType(event.target.value as TableType)}
            >
                <MenuItem value='outscraper'>Outscraper</MenuItem>
                <MenuItem value='texau'>Texau</MenuItem>
            </Select>
        </FormControl>
    </Box>;
}