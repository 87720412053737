import { FilesProvider } from "./components/fileContext";
import { FilesDisplay } from "./components/fileDisplay";
import { FilesAddButton, FilesUploadButton } from "./components/fileUpload";
import { SelectTable } from "./components/selectTable";

export const CSVUploaderPage = () => {
  return <FilesProvider>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '25px', justifyContent: 'start', paddingTop: '15px', height: '95%', alignItems: 'center' }}>
        <div className='Files' style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '80%', gap: '25px', justifyContent: 'center', alignItems: 'start' }}>
          <FilesDisplay />
          <FilesAddButton />
        </div>

        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', gap: '25px', justifyContent: 'center', alignItems: 'center' }}>
          <SelectTable />
          <FilesUploadButton />
        </div>
      </div>
  </FilesProvider >
};