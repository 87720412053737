import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { ExtendedFile, FilesContext, TableType } from './fileContext';

const url = 'https://us-central1-etl-directories1.cloudfunctions.net/cf_csv_uploader' // http://localhost:8080

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export const FilesAddButton = () => {
    const context = useContext(FilesContext);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles: File[] = Array.from(event.target.files ? event.target.files : []);
        context.addFiles(selectedFiles);
        console.log('added files')
    };

    return <Button
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        disabled={context.uploading}
    // startIcon={<CloudUploadIcon />}
    >
        Add files
        <VisuallyHiddenInput
            type="file"
            multiple
            accept=".csv"
            onChange={handleFileChange}
        />
    </Button>;
};

export const FilesUploadButton = () => {
    const context = useContext(FilesContext);

    return <Button
        component="label"
        variant="contained"
        disabled={context.uploading}
        tabIndex={-1}
        startIcon={<CloudUploadIcon />}
        onClick={() => context.uploadFiles()}
    >
        Upload to BigQuery
    </Button>;
};

export const uploadFile = async (file: ExtendedFile, tableType: TableType, password: string) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('table_type', tableType);
    formData.append('password', password);
    const response = await fetch(url, { method: 'POST', body: formData, });

    if (!response.ok) {
        throw new Error(await response.text() || 'Upload failed.');
    };

    return 'Upload successful!';
};
