import { ETLHistory } from './EtlHistory';
import { EtlProvider } from './EtlContext';
import { ETLTriggerButton } from './EtlTriggerButton';
import { ETLTruncateButton } from './EtlTruncateButton';

export const ETLTriggerPage = () => {
  return <EtlProvider>
    <div style={{ flexGrow: 1, maxHeight: '90%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <ETLHistory />
      <br />
      <div style={{display: 'flex', flexDirection: 'column', gap: '25px'}}>
        <ETLTriggerButton />
        <ETLTruncateButton />
      </div>
    </div>
  </EtlProvider>
};