import { Button, TextField } from '@mui/material';
import React, { createContext, useState, ReactNode, useContext } from 'react';

interface AuthContextInterface {
    password: string;
    setPassword: (password: string) => void;
    loggedIn: boolean;
    checkPassword: () => void;
};

export const AuthContext = createContext<AuthContextInterface>({ password: '', loggedIn: false, setPassword: () => { }, checkPassword: () => { } });

// Create a provider component
export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [password, setPassword] = useState('');
    const [loggedIn, setLoggedIn] = useState<boolean>(false);

    const checkPassword = () => {
        password == 'imsEDmoUTi' ? setLoggedIn(true) : setLoggedIn(false);
    };

    return <AuthContext.Provider value={{ password, setPassword, loggedIn, checkPassword }}>
        {loggedIn
            ? children
            : <LoginPage />
        }
    </AuthContext.Provider>
};

const LoginPage = () => {
    const context = useContext(AuthContext);

    return <div style={{display: 'flex', gap: '15px', justifyContent: 'center', alignItems: 'center'}}>
        <TextField
            label="Password"
            type="password"
            name='password'
            variant="outlined"
            autoComplete="current-password"
            value={context.password}
            onChange={(e) => context.setPassword(e.target.value)}
            size='small'
        />
        <Button variant="contained" color="primary" onClick={() => context.checkPassword()}>
            Login
        </Button>
    </div>;
};