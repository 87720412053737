import * as React from 'react';

import { AuthProvider } from './sections/auth/authContext';
import { AppsDrawer, apps } from './AppDrawer';
import { Typography } from '@mui/material';

const App = () => {
  const [selectedApp, setSelectedApp] = React.useState(apps[1]);

  return <div className="app" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100dvh', alignItems: 'center', padding: '3vh 4vw' }}>
    <AuthProvider>
      <AppsDrawer setSelectedApp={setSelectedApp} />
      <div style={{ width: '20vw', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '5vh' }}>
        <Typography >
          {selectedApp.name}
        </Typography>
        <hr />
      </div>
      <div className="page" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100dvh', alignItems: 'center', padding: '3vh 4vw' }}>
        {selectedApp.app}
      </div>
    </AuthProvider>
  </div>
};

export default App;